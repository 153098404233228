@import-normalize;

html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.alert {
  z-index:1; 
  border-radius:0px
}

.home_hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)), url('https://www.thedrive.com/content/2022/01/Planes-At-Gates-Airport.jpg?quality=85');
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}
